import { loadToken, setTokenValue } from '@src/utils/tokenUtils';
import { transformPhonenumber } from '@src/form/transformation';
import {
	fetchCustomerInfoApiCall,
	azureAuthApiCall,
	getActiveMembershipApiCall,
	getCustomerCreditCardApiCall,
	errorLogExceptionToSF,
	isInvalidSession,
} from '@src/custom-api/kinto-api/client';
import { del, TOKEN_KEY } from '@src/utils/localStorage';
import { setCookie } from '@src/utils/cookieUtil';
import { setToken } from '@src/custom-api/azureApiGateway/client';
import { useAppDispatch } from '@src/app/hooks';
import { setAuthenticationToken } from '@src/app/features/user.slice';

/**
 * @deprecated Introducing redux toolkit as store instead, use user.slice.ts or transfer functionality.
 */
const customer = {
	name: 'customer',
	getReducer: () => {
		const initialState = {
			token: loadToken(),
			firstName: '',
			lastName: '',
			phonenumber: '',
			customerId: '',
			email: '',
			ssn: '',
			address: [],
			drivingLicense: {},
			showMissingInformation: false,
			showAccountBlockedInformation: false,
			showAccountNotAllowedInformation: false,
			unsuccessfulLogin: false,
			creditCards: [],
			defaultCreditCard: null,
			canReserve: null,
			canReserveBusiness: null,
			canSchedule: null,
			canScheduleBusiness: null,
			phonenumberVerified: null,
			validPaymentCard: null,
			loading: false,
			lastError: null,
			driverLicenseExpDate: null,
			businessEntity: {},
			b2bDomainOnboarding: false,
			membershipId: null,
			no_bank_id_option: null,
			skipAddingPaymentMethod: false,
		};
		return (state = initialState, { type, payload }) => {
			switch (type) {
				case 'SET_CUSTOMER_INFO':
					const formattedPayload = {
						...payload,
						phonenumber: payload.phonenumber ? transformPhonenumber(payload.phonenumber) : undefined,
					};
					return { ...state, ...formattedPayload };
				case 'RESET_CUSTOMER_INFO':
					return { ...initialState, token: null };
				case 'SET_SHOW_MISSING_INFORMATION':
					return { ...state, showMissingInformation: payload };
				case 'SET_SHOW_ACCOUNT_BLOCKED_INFORMATION':
					return { ...state, showAccountBlockedInformation: payload };
				case 'SET_SHOW_ACCOUNT_NOT_ALLOWED_INFORMATION':
					return { ...state, showAccountNotAllowedInformation: payload };
				case 'SET_UNSUCESSFUL_LOGIN':
					return { ...state, unsuccessfulLogin: payload };
				case 'SET_CUSTOMER_CREDIT_CARDS':
					return { ...state, ...payload };
				case 'RESET_CUSTOMER_CREDIT_CARDS':
					return { ...state, creditCards: [], defaultCreditCard: null };
				case 'FETCH_CUSTOMER_INFO_STARTED':
					return { ...state, loading: true, lastError: null };
				case 'FETCH_CUSTOMER_INFO_SUCCESS':
					return { ...state, loading: false, lastError: null };
				case 'FETCH_CUSTOMER_INFO_FAILURE':
					return { ...state, loading: false, lastError: payload };
				case 'SET_MEMBERSHIP':
					return { ...state, ...payload };
				default:
					return state;
			}
		};
	},
	doSetCustomerInfo:
		payload =>
		({ dispatch }) => {
			dispatch({ type: 'SET_CUSTOMER_INFO', payload });
		},
	doResetCustomer:
		() =>
		({ dispatch }) => {
			dispatch({ type: 'RESET_CUSTOMER_INFO', payload: null });
		},
	doRefreshCustomerInfo:
		() =>
		async ({ dispatch, store }) => {
			dispatch({ type: 'FETCH_CUSTOMER_INFO_STARTED' });
			try {
				const newCustomerInfo = await fetchCustomerInfoApiCall(store.selectMarket());
				if (newCustomerInfo.isAge20DkBlocked) {
					store.doSetShowAccountBlockedInformation(true);
				}
				dispatch({
					type: 'SET_CUSTOMER_INFO',
					payload: newCustomerInfo,
				});
				await store.doSetCustomerCreditCards();
				dispatch({ type: 'FETCH_CUSTOMER_INFO_SUCCESS' });
				return newCustomerInfo;
			} catch (error) {
				if (isInvalidSession(error)) {
					store.doSetError({
						titleKey: 'kinto.auth.invalid-user-session-title',
						messageKey: 'kinto.auth.invalid-user-session-message',
						redirectOnClose: true,
					});
					store.doLogoutCustomer();
				} else {
					dispatch({ type: 'FETCH_CUSTOMER_INFO_FAILURE', payload: error });
				}
			}
		},
	doLoginCustomer:
		(email, password, keepCustomerLoggedIn = false) =>
		async ({ dispatch, store }) => {
			try {
				const response = await azureAuthApiCall(email, password, keepCustomerLoggedIn);
				if (response.data && response.data.auth_token) {
					dispatch({
						type: 'SET_UNSUCESSFUL_LOGIN',
						payload: false,
					});
					dispatch({
						type: 'SET_CUSTOMER_INFO',
						payload: {
							token: response.data.auth_token,
						},
					});
					return await store.doRefreshCustomerInfo();
				} else {
					throw new Error('Failed login attempt');
				}
			} catch (error) {
				errorLogExceptionToSF(error, 'Kinto web, customer login', 'Login page');
				dispatch({
					type: 'SET_UNSUCESSFUL_LOGIN',
					payload: true,
				});
				return false;
			}
		},
	doLogoutCustomer:
		() =>
		async ({ store }) => {
			del(TOKEN_KEY);
			setCookie(TOKEN_KEY, '');
			setToken(null);
			setTokenValue(null);
			store.doResetCustomer();
			store.doClearOnfidoSdkToken();
		},
	doSetShowMissingInformation:
		payload =>
		({ dispatch }) => {
			dispatch({
				type: 'SET_SHOW_MISSING_INFORMATION',
				payload,
			});
		},
	doSetShowAccountBlockedInformation:
		payload =>
		({ dispatch }) => {
			dispatch({
				type: 'SET_SHOW_ACCOUNT_BLOCKED_INFORMATION',
				payload,
			});
		},
	doSetShowAccountNotAllowedInformation:
		payload =>
		({ dispatch }) => {
			dispatch({
				type: 'SET_SHOW_ACCOUNT_NOT_ALLOWED_INFORMATION',
				payload,
			});
		},
	doSetCustomerCreditCards:
		() =>
		async ({ dispatch, getState }) => {
			const state = getState();
			const { customerId, token } = state.customer;
			const isPossibleToFetch = !!token && customerId && customerId !== '';
			if (isPossibleToFetch) {
				const resp = await getCustomerCreditCardApiCall(customerId, state.site.language);
				let defaultCreditCard = -1;
				resp.data.cards.forEach(card => {
					if (card.in_use && card.is_valid) {
						defaultCreditCard = card;
					}
				});
				if (resp.data.cards && resp.data.cards.length > 0) {
					dispatch({
						type: 'SET_CUSTOMER_CREDIT_CARDS',
						payload: { creditCards: resp.data.cards, defaultCreditCard },
					});
				} else {
					dispatch({
						type: 'SET_CUSTOMER_CREDIT_CARDS',
						payload: { creditCards: [], defaultCreditCard },
					});
				}
			}
		},
	doResetCustomerCreditCards:
		() =>
		({ dispatch }) => {
			dispatch({ type: 'RESET_CUSTOMER_CREDIT_CARDS', payload: null });
		},
	selectCustomerInfo: state => {
		return {
			...state.customer,
		};
	},
	selectIsCustomerLoggedIn: state => !!state.customer.token,
	selectShowMissingInformation: state => !!state.customer.showMissingInformation,
	selectShowAccountBlockedInformation: state => {
		return state.customer?.showAccountBlockedInformation;
	},
	selectShowAccountNotAllowedInformation: state => {
		return state.customer?.showAccountNotAllowedInformation;
	},
	selectIsMissingInformation: state => {
		if (state.customer.canSchedule === null || state.customer.phonenumberVerified === null) {
			return null;
		}
		if (state.customer.canSchedule === undefined || state.customer.canSchedule === null) {
			return !state.customer.canReserve || !state.customer.phonenumberVerified;
		}
		return !state.customer.canSchedule || !state.customer.phonenumberVerified;
	},
	selectIsMissingInformationBusiness: state => {
		if (state.customer.canScheduleBusiness === null) {
			return null;
		}
		if (state.customer.canScheduleBusiness === undefined || state.customer.canScheduleBusiness === null) {
			return !state.customer.canReserveBusiness;
		}
		return !state.customer.canScheduleBusiness;
	},
	selectBusinessCustomer: state => {
		if (!!state.customer.businessEntity?.id || !!state.customer.b2bDomainOnboarding) {
			return true;
		}
		return false;
	},
	selectUnsuccessfulLogin: state => !!state.customer.unsuccessfulLogin,
	selectCustomerCreditCards: state => state.customer.creditCards,
	selectCustomerInfoLoading: state => state.customer.loading,
	selectMembership: state => state.customer.membershipId,
	doSetMembership:
		() =>
		async ({ dispatch, getState }) => {
			const state = await getState();
			const { customerId, token } = state.customer;
			const isPossibleToFetch = !!token && customerId && customerId !== '';
			if (isPossibleToFetch) {
				const activeMembership = await getActiveMembershipApiCall(customerId);
				const membershipId =
					activeMembership.data &&
					activeMembership.data.active_membership !== null &&
					activeMembership.data.active_membership.membership_id;

				if (
					membershipId !== null &&
					membershipId !== undefined &&
					membershipId !== NaN &&
					membershipId !== ''
				) {
					dispatch({
						type: 'SET_MEMBERSHIP',
						payload: { membershipId: membershipId },
					});
				}
			}
		},
};

export default customer;
